:root {
  --border: 1px solid black;
}

.custom-table {
  width: 100%;
  box-shadow: var(--bottom-border) rgb(63, 63, 63);
}

.custom-table > thead {
  color: white;
  box-shadow: var(--bottom-border) #214161;
  font-weight: bold;
  font-size: 1.3em;
}

.custom-table.blue > thead {
  background-color: var(--blue);
}

.custom-table.orange > thead {
  background-color: var(--orange);
}

.custom-table, .custom-table > thead, .custom-table > thead > tr, .custom-table > thead > tr > td,
.custom-table > tbody > tr, .custom-table > tbody > tr > td {
  /* border: var(--border); */
  text-align: center;
}

.custom-table tr {
  height: 50px;
}

.custom-table tr.disabled {
  cursor: not-allowed;
  background-color: var(--light-grey);
}

.custom-table tr.disabled:hover {
  cursor: not-allowed;
  background-color: var(--grey);
}

.custom-table thead td:hover {
  background-color: var(--dark-blue);
  cursor: pointer;
}

.custom-table.blue thead td:hover {
  background-color: var(--dark-blue);
}

.custom-table.orange thead td:hover {
  background-color: var(--dark-orange);
}

.custom-table tbody > tr:hover {
  color: white;
  cursor: pointer;
  box-shadow: var(--bottom-border) var(--light-grey);
} 

.custom-table.blue tbody > tr:hover {
  background-color: var(--orange);
} 

.custom-table.orange tbody > tr:hover {
  background-color: var(--blue);
} 

.custom-table td {
  padding: var(--container-padding);
}

.custom-table tbody > tr {
  border-bottom: 1px solid var(--light-grey) ;
}